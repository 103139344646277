import Vue from 'vue';
import Axios from 'axios';
import VueAxios from 'vue-axios';
import ErrorHandler from '@/utils/error.handler';

Vue.use(VueAxios, Axios);

export default function downloadMedia(media, token, report = false) {
  let url = '';
  let mediaType = {};

  if (report) {
    mediaType = media;
    url = `${media.file}?token=${token}`;
  } else {
    const { type } = media;
    mediaType = media[type];
    url = `${mediaType.url}?token=${token}`;
  }

  Axios.get(url, { responseType: 'blob' })
    .then((response) => {
      const blob = new Blob([response.data], { type: mediaType.mimetype });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = mediaType.filename;
      link.click();
      URL.revokeObjectURL(link.href);
    }).catch(ErrorHandler.logErrors);
}
